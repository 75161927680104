import React from 'react'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import Form from 'reactstrap/lib/Form'
import FormGroup from 'reactstrap/lib/FormGroup'
import Button from 'reactstrap/lib/Button'
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import {
  faCheck,
  faTimes,
  faUserMinus
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useAlert } from 'react-alert'

import CustomFormField from '../partials/CustomFormField'
import Checkbox from '../partials/CheckboxFormField'
import {
  CREATE_STUDENT,
  DELETE_STUDENT,
  studentParts,
  UPDATE_STUDENT
} from '../../gql/students'
import { FETCH_LOCATION } from '../../gql/locations'

const initialValues = {
  name: '',
  dob: '',
  food: [],
  foodOther: '',
  currentClub: '',
  remarks: ''
}

const Student = ({ student, setEditing }) => {
  const { location } = useParams()
  const alert = useAlert()

  const refetchQueries = [
    { query: FETCH_LOCATION, variables: { path: location } }
  ]

  const [createStudent] = useMutation(CREATE_STUDENT, {
    onCompleted: () => {
      setEditing(null)
      alert.success('Kind toegevoegd aan uw account')
    },
    update (cache, { data: { createStudent } }) {
      cache.modify({
        fields: {
          allStudents (existingStudents = []) {
            const newStudentRef = cache.writeFragment({
              data: createStudent,
              fragment: studentParts,
              fragmentName: 'StudentParts'
            })
            return [...existingStudents, newStudentRef]
          }
        }
      })
    },
    refetchQueries
  })

  const [updateStudent] = useMutation(UPDATE_STUDENT, {
    onCompleted: () => {
      setEditing(null)
      alert.success('Kind successvol aangepast')
    }
  })

  const [deleteStudent] = useMutation(DELETE_STUDENT, {
    onCompleted: () => {
      setEditing(null)
      alert.success('Kind verwijderd van account')
    },
    refetchQueries
  })

  const handleDelete = () => {
    if (
      window.confirm(
        'Bent u zeker dat u dit kind van uw account wilt verwijderen?'
      )
    ) {
      deleteStudent({
        variables: { id: student.id },
        update (cache) {
          cache.modify({
            fields: {
              allStudents (existingStudents = [], { readField }) {
                return existingStudents.filter(
                  studentRef => student.id !== readField('id', studentRef)
                )
              }
            }
          })
        }
      })
    }
  }

  const handleSubmit = doc =>
    student.id
      ? updateStudent({ variables: { id: student.id, ...doc } })
      : createStudent({ variables: doc })

  return (
    <Formik
      initialValues={Object.assign(initialValues, student || {})}
      validationSchema={Yup.object({
        name: Yup.string().required('verplicht veld'),
        dob: Yup.string().required('verplicht veld')
      })}
      onSubmit={handleSubmit}
    >
      {formik => (
        <Form onSubmit={formik.handleSubmit}>
          <Row form>
            <Col sm='4'>
              <FormGroup>
                <label htmlFor='name'>Naam kind</label>
                <Field
                  id='name'
                  name='name'
                  type='text'
                  component={CustomFormField}
                />
              </FormGroup>
            </Col>
            <Col sm='4'>
              <FormGroup>
                <label htmlFor='dob'>Geboortedatum</label>
                <Field
                  id='dob'
                  name='dob'
                  type='date'
                  component={CustomFormField}
                />
              </FormGroup>
            </Col>
            <Col sm='4'>
              <FormGroup>
                <label htmlFor='currentClub'>Huidige voetbalclub</label>
                <Field
                  id='currentClub'
                  name='currentClub'
                  type='text'
                  component={CustomFormField}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col sm='4'>
              <FormGroup>
                <label htmlFor='food'>Speciale eter?</label>
                <Field
                  name='food'
                  value='glutenvrij'
                  label='Glutenvrij'
                  component={Checkbox}
                />
                <Field
                  name='food'
                  value='halal'
                  label='Halal'
                  component={Checkbox}
                />
                <Field
                  name='food'
                  value='vegetarisch'
                  label='Vegetarisch'
                  component={Checkbox}
                />
              </FormGroup>
            </Col>
            <Col sm='8'>
              <FormGroup>
                <label htmlFor='foodOther'>Andere eetgewoonten</label>
                <Field
                  id='foodOther'
                  name='foodOther'
                  type='textarea'
                  component={CustomFormField}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col sm='12'>
              <p>
                Heeft uw kind enige lichamelijke beperkingen, blessures,
                voedselallergieën of andere zaken die van belang zijn tijdens de
                voetbalstage? Laat het ons via 'opmerkingen' weten.
              </p>
              <FormGroup>
                <label htmlFor='remarks'>Opmerkingen</label>
                <Field
                  id='remarks'
                  name='remarks'
                  type='textarea'
                  rows='2'
                  component={CustomFormField}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col>
              <Button color='success' type='submit' block>
                <FontAwesomeIcon icon={faCheck} /> Opslaan
              </Button>
            </Col>
            {student && (
              <Col>
                <Button color='danger' block onClick={handleDelete}>
                  <FontAwesomeIcon icon={faUserMinus} /> Verwijderen
                </Button>
              </Col>
            )}
            <Col>
              <Button
                outline
                color='warning'
                block
                onClick={() => setEditing(null)}
              >
                <FontAwesomeIcon icon={faTimes} /> Annuleren
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default Student
