import React from 'react'
import Spinner from 'reactstrap/lib/Spinner'

const Loader = () => {
  return (
    <div>
      <Spinner />
    </div>
  )
}

export default Loader
