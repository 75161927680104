import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  concat
} from '@apollo/client'
import { cartFields, updateLocalstorage } from './gql/cart'

const httpLink = new HttpLink({ uri: '/graphql' })

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) =>
      key === '__typename' ? undefined : value
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    )
  }
  return forward(operation).map(data => {
    return data
  })
})

const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: { ...cartFields }
      }
    }
  }),
  link: concat(cleanTypeName, httpLink)
})

updateLocalstorage(apolloClient)

export default apolloClient
