import React from 'react'

import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import ListGroup from 'reactstrap/lib/ListGroup'
import Alert from 'reactstrap/lib/Alert'

import orderBy from 'lodash/orderBy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import CampCard from './CampCard'

const Location = ({ logo, name, addressString, extInfo, message, camps }) => (
  <div>
    <Row className='align-items-center mb-3'>
      <Col sm='2' xs='3'>
        <img
          src={logo?.uri ? logo.uri : '/voetbalstages-logo.png'}
          alt={`${name} logo`}
          className='img-fluid'
        />
      </Col>
      <Col className='pl-0'>
        <h3>{name}</h3>
        <p className='mb-1'>{addressString}</p>
        <a href={extInfo} target='_blank' rel='noopener noreferrer'>
          Meer info over deze locatie
        </a>
      </Col>
    </Row>
    {message && (
      <Alert color='info'>
        <FontAwesomeIcon icon={faExclamationTriangle} /> {message}
      </Alert>
    )}
    {camps[0] ? (
      <ListGroup>
        {orderBy(camps, 'from').map((camp, i) => (
          <CampCard key={i} id={camp.id} />
        ))}
      </ListGroup>
    ) : (
      <Alert color='warning'>
        <FontAwesomeIcon icon={faExclamationTriangle} /> De stages op deze
        locatie moeten nog bekend gemaakt worden. Kom op een later moment terug.
      </Alert>
    )}
  </div>
)

export default Location
