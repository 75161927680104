import React from 'react'
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import Form from 'reactstrap/lib/Form'
import FormGroup from 'reactstrap/lib/FormGroup'
import Button from 'reactstrap/lib/Button'
import { Formik, Field } from 'formik'
import * as yup from 'yup'

import CustomFormField from '../partials/CustomFormField'

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required('verplicht veld')
    .min(6, 'Wachtwoord moet uit minstens 6 tekens bestaan'),
  repeatPassword: yup
    .string()
    .required('verplicht veld')
    .when('password', {
      is: val => !!(val && val.length > 0),
      then: yup
        .string()
        .oneOf([yup.ref('password')], 'Wachtwoord komt niet overeen')
    })
})

const Register = ({
  match: {
    params: { token }
  }
}) => (
  <Row className='my-3'>
    <Col sm={{ size: 4, offset: 4 }}>
      <Formik
        initialValues={{
          password: '',
          repeatPassword: ''
        }}
        validationSchema={validationSchema}
        onSubmit={async doc => {
          try {
            doc.token = token
            const res = await fetch('/auth/set_password', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              redirect: 'follow',
              mode: 'same-origin',
              body: JSON.stringify(doc)
            })
            if (res.redirected) {
              window.location.href = res.url
            }
          } catch (err) {
            console.error(err)
          }
        }}
      >
        {formik => (
          <Form onSubmit={formik.handleSubmit} className='my-3'>
            <FormGroup>
              <label htmlFor='password'>Wachtwoord</label>
              <Field
                id='password'
                name='password'
                type='password'
                component={CustomFormField}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor='repeatPassword'>Herhaal wachtwoord</label>
              <Field
                id='repeatPassword'
                name='repeatPassword'
                type='password'
                component={CustomFormField}
              />
            </FormGroup>
            <Button type='submit' block color='primary'>
              Wachtwoord opnieuw instellen
            </Button>
          </Form>
        )}
      </Formik>
    </Col>
  </Row>
)

export default Register
