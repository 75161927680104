import React, { useState } from 'react'

import Alert from 'reactstrap/lib/Alert'
import Button from 'reactstrap/lib/Button'

const CookieConsent = () => {
  const [agreed, setAgreed] = useState(
    window.localStorage.getItem('svs__cookie__consent')
  )
  const agree = () => {
    window.localStorage.setItem('svs__cookie__consent', 'agreed')
    setAgreed(true)
  }
  if (agreed) return null
  return (
    <Alert color='info' className='fixed-bottom mb-0 rounded-0 text-center'>
      Deze website maakt gebruik van cookies om de gebruikservaring te
      verbeteren.{' '}
      <Button color='success' size='sm' onClick={agree}>
        Akkoord
      </Button>
    </Alert>
  )
}

export default CookieConsent
