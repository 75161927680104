import { gql } from '@apollo/client'

const studentParts = gql`
  fragment StudentParts on Student {
    id
    name
    dob
    owner {
      id
      firstName
      lastName
      email
    }
  }
`

const FETCH_STUDENTS = gql`
  query Students {
    allStudents {
      ...StudentParts
    }
  }
  ${studentParts}
`

const FETCH_STUDENT = gql`
  query Student {
    Student {
      ...StudentParts
    }
  }
  ${studentParts}
`

const mutationVars = `
  $name: String
  $dob: String
  $food: [String]
  $foodOther: String
  $currentClub: String
  $remarks: String
`

const muationInput = `
  name: $name
  dob: $dob
  food: $food
  foodOther: $foodOther
  currentClub: $currentClub
  remarks: $remarks
`

const CREATE_STUDENT = gql`
  mutation CreateStudent(${mutationVars}) {
    createStudent(${muationInput}) {
      ...StudentParts
    }
  }
  ${studentParts}
`

const UPDATE_STUDENT = gql`
  mutation UpdateStudent(
    $id: ID!
    ${mutationVars}
  ) {
    updateStudent(
      id: $id
      ${muationInput}
    ) {
      ...StudentParts
    }
  }
  ${studentParts}
`

const DELETE_STUDENT = gql`
  mutation DeleteStudent($id: ID!) {
    deleteStudent(id: $id) {
      id
    }
  }
`

export {
  FETCH_STUDENTS,
  FETCH_STUDENT,
  CREATE_STUDENT,
  UPDATE_STUDENT,
  DELETE_STUDENT,
  studentParts
}
