module.exports = {
  googleClientID: process.env.GOOGLE_CLIENT_ID,
  googleClientSecret: process.env.GOOGLE_CLIENT_SECRET,
  facebookAppID: process.env.FACEBOOK_APP_ID,
  facebookAppSecret: process.env.FACEBOOK_APP_SECRET,
  mongoURI: process.env.MONGO_URI,
  cookieKey: process.env.COOKIE_KEY,
  baseURL: process.env.BASE_URL,
  AWSAccessKeyId: process.env.AWS_SES_USER_KEY,
  AWSSecretAccessKey: process.env.AWS_SES_USER_SECRET,
  AWSRegion: process.env.AWS_SES_REGION,
  s3: {
    accessKeyId: process.env.AWS_S3_USER_KEY,
    secretAccessKey: process.env.AWS_S3_USER_SECRET,
    region: 'eu-central-1'
  },
  googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
  googlePlacesApiKey: process.env.GOOGLE_PLACES_API_KEY,
  bugsnagApiKey: process.env.BUGSNAG_API_KEY,
  nextAuthSecret: process.env.NEXT_AUTH_SECRET,
  mollieRefreshToken: process.env.MOLLIE_REFRESH_TOKEN,
  mollieClientId: process.env.MOLLIE_CLIENT_ID,
  mollieClientSecret: process.env.MOLLIE_CLIENT_SECRET,
  mollieTestmode: false,
  applicationFee: process.env.APPLICATION_FEE
}
