import React from 'react'
import Badge from 'reactstrap/lib/Badge'
import dayjs from 'dayjs'

const DateRange = ({ from, to }) => (
  <>
    van <Badge color='info'>{dayjs(from).format('DD-MM-YYYY')}</Badge> tot{' '}
    <Badge color='info'>{dayjs(to).format('DD-MM-YYYY')}</Badge>
  </>
)

export default DateRange
