import { gql } from '@apollo/client'
import dayjs from 'dayjs'

const locationParts = gql`
  fragment LocationParts on Location {
    id
    path
    name
    logo {
      uri
    }
    address {
      lat
      lng
    }
    addressString
    organization {
      id
      name
    }
  }
`

const FETCH_LOCATIONS = gql`
  query Locations {
    allLocations(filter: { visibility: "VISIBLE" }) {
      ...LocationParts
    }
  }
  ${locationParts}
`

const year = dayjs()
  .add(6, 'weeks')
  .year()

const FETCH_LOCATION = gql`
  query Location($id: ID, $path: String) {
    Location(id: $id, path: $path) {
      ...LocationParts
      addressString
      extInfo
      message
      camps(filter: {year: ${year}}) {
        id
        from
        availableStudents {
          id
        }
      }
    }
  }
  ${locationParts}
`

export { FETCH_LOCATIONS, FETCH_LOCATION, locationParts }
