import { useQuery } from '@apollo/client'
import React from 'react'
import Alert from 'reactstrap/lib/Alert'
import { FETCH_ORDERS } from '../../gql/orders'
import Loader from '../partials/Loader'
import OrderList from '../partials/OrderList'

const Orders = () => {
  const { data, error, loading } = useQuery(FETCH_ORDERS)

  if (loading) return <Loader />
  if (error) console.error(error)

  const { allOrders } = data

  return (
    <div className='my-3'>
      <h1>Bestellingen</h1>
      <p>
        Hieronder vindt u een overzicht van uw bestellingen. Een bestelling
        bestaat uit één of meerdere inschrijvingen. Hierdoor kan u de betaling
        van een aantal inschrijvingen in één keer afhandelen.
      </p>
      {allOrders.length > 0 ? (
        <OrderList orders={allOrders} />
      ) : (
        <Alert color='secondary'>Geen bestellingen gevonden.</Alert>
      )}
    </div>
  )
}

export default Orders
