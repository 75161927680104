import React from 'react'

const Error = props => {
  console.log(props)
  return (
    <div
      className='container d-flex justify-content-center align-items-center'
      style={{ height: '100vh' }}
    >
      <div className='alert alert-danger'>
        <h1>Oeps...!</h1>
        <hr />
        <p>
          Er ging iets mis. Onze excuses! Stuur een mailtje naar{' '}
          <a href='mailto:platform@voetbalstages.be' className='alert-link'>
            platform@voetbalstages.be
          </a>{' '}
          als het probleem zich blijft voor doen.
        </p>
        <a href='/' className='alert-link'>
          ← Keer terug
        </a>
      </div>
    </div>
  )
}

export default Error
