import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useLazyQuery, useQuery } from '@apollo/client'

import Location from './Location'
import { FETCH_LOCATION, FETCH_LOCATIONS } from '../../gql/locations'
import Loader from '../partials/Loader'
import Map from './Map'

const Locations = () => {
  const { data, loading } = useQuery(FETCH_LOCATIONS)

  const { locationPath } = useParams()
  const history = useHistory()
  const [selectedLocation, setSelectedLocation] = useState(null)

  const [
    loadLocation,
    { loading: locationLoading, data: locationData }
  ] = useLazyQuery(FETCH_LOCATION, {
    variables: { path: locationPath }
  })

  useEffect(() => {
    if (locationPath) {
      loadLocation()
      document.getElementById('location-detail')?.scrollIntoView()
    }
  }, [locationPath, loadLocation])

  useEffect(() => {
    if (locationData) {
      setSelectedLocation(locationData.Location)
      if (!locationLoading && !locationData.Location) {
        history.push('/')
      }
    }
  }, [locationData, locationLoading, history])

  const onSelect = path => history.push(path)

  if (loading) return <Loader />

  return (
    <>
      <Map {...data} onSelect={onSelect} selectedLocation={selectedLocation} />
      {locationLoading ? (
        <Loader />
      ) : (
        locationData &&
        locationData.Location && (
          <div id='location-detail' className='mt-3'>
            <Location {...locationData.Location} />
            <h4 className='mt-4'>Bekijk andere locaties:</h4>
          </div>
        )
      )}
    </>
  )
}

export default Locations
