import React from 'react'
import { Field } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import get from 'lodash/get'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import InputGroup from 'reactstrap/lib/InputGroup'
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon'
import Input from 'reactstrap/lib/Input'
import Button from 'reactstrap/lib/Button'
import FormFeedback from 'reactstrap/lib/FormFeedback'

const CustomFormField = ({
  field,
  form: { touched, errors },
  options,
  onRemove,
  ...props
}) => {
  return (
    <>
      <InputGroup className='mb-2'>
        <Input
          type='text'
          invalid={!!(get(touched, field.name) && get(errors, field.name))}
          {...field}
          {...props}
        />
        <InputGroupAddon addonType='append'>
          <Button color='warning' outline onClick={onRemove}>
            <FontAwesomeIcon icon={faMinus} />
          </Button>
        </InputGroupAddon>
        {get(touched, field.name) && get(errors, field.name) && (
          <FormFeedback>{get(errors, field.name)}</FormFeedback>
        )}
      </InputGroup>
    </>
  )
}

const ArrayFormField = ({ push, form: { values, errors }, remove, name }) => (
  <div>
    {values[name] &&
      values[name].map((value, index) => (
        <Field
          name={`${name}.${index}`}
          component={CustomFormField}
          onRemove={() => remove(index)}
          key={`form-array-${name}-${index}`}
        />
      ))}
    <Button color='success' outline onClick={() => push('')}>
      <FontAwesomeIcon icon={faPlus} /> <small>Nog één toevoegen</small>
    </Button>
  </div>
)

export default ArrayFormField
