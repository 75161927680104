import React, { cloneElement, useState } from 'react'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'

const DashboardElement = ({ icon, title, children }) => {
  const [completed, setCompleted] = useState(false)
  return (
    <Row className='bg-light my-3 pt-3'>
      <Col xs='2' sm='1' className='text-center'>
        <FontAwesomeIcon icon={icon} size='3x' className='mb-2' />
      </Col>
      <Col>
        <h2 className='h4'>
          {title}{' '}
          {completed && (
            <FontAwesomeIcon icon={faCheck} className='text-success' />
          )}
        </h2>
      </Col>
      <Col xs='12'>
        <div className='my-3'>{cloneElement(children, { setCompleted })}</div>
      </Col>
    </Row>
  )
}

export default DashboardElement
