import React from 'react'

import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import UncontrolledDropdown from 'reactstrap/lib/UncontrolledDropdown'
import DropdownToggle from 'reactstrap/lib/DropdownToggle'
import DropdownMenu from 'reactstrap/lib/DropdownMenu'
import Button from 'reactstrap/lib/Button'
import Badge from 'reactstrap/lib/Badge'
import ListGroupItem from 'reactstrap/lib/ListGroupItem'
import {
  faCartPlus,
  faCheck,
  faTimes,
  faPencilAlt,
  faBan
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import { useQuery } from '@apollo/client'

import { currency, periodMap } from '../../helpers'
import DateRange from '../partials/DateRange'
import { FETCH_CAMP } from '../../gql/camps'
import cart from '../../gql/cart'
import { CART_INCLUDES } from '../../gql/cart'

const DropdownItem = ({ children, name }) => (
  <span className='dropdown-item d-flex justify-content-between align-items-center'>
    <span className='mr-3'>{name}</span>
    {children}
  </span>
)

const CartActions = ({ student, camp }) => {
  const {
    data: { cartIncludes }
  } = useQuery(CART_INCLUDES, { variables: { student, camp } })

  return cartIncludes ? (
    <Button
      outline
      color='warning'
      onClick={() => cart.removeItem({ student, camp })}
    >
      <FontAwesomeIcon icon={faTimes} /> Annuleren
    </Button>
  ) : (
    <Button
      outline
      color='success'
      onClick={() =>
        cart.addItem({
          student,
          camp,
          tshirtSize: '',
          gadgets: []
        })
      }
    >
      <FontAwesomeIcon icon={faCartPlus} /> Toevoegen
    </Button>
  )
}

const CampCard = ({ id }) => {
  const { data, loading, error } = useQuery(FETCH_CAMP, { variables: { id } })

  if (loading) return null
  if (error) console.error(error)

  const camp = data.Camp

  const {
    name,
    period,
    status,
    duration,
    from,
    to,
    costDetail: { cost, currentDiscount, currentDiscountToInc, deposit },
    registeredStudents,
    availableStudents
  } = camp

  if (status === 'HIDDEN') return null

  const view =
    status === 'CANCELED'
      ? 'canceled'
      : (availableStudents.length === 0 && registeredStudents.length === 0) ||
        status === 'CLOSED'
      ? 'disabled'
      : 'registrations'

  return (
    <ListGroupItem className='py-2'>
      <Row className='align-items-center'>
        <Col sm='2'>
          <h5 className='m-0'>{name || periodMap[period]}</h5>
          <small>
            <i>{duration} dagen</i>
          </small>
        </Col>
        <Col>
          <DateRange {...{ from, to }} />
        </Col>
        <Col sm='3'>
          {currentDiscount ? (
            <>
              <h5 className='m-0'>
                <Badge color='danger'>
                  <s
                    style={{
                      textDecorationThickness: '2px',
                      textDecorationColor: 'red'
                    }}
                  >
                    {currency(cost)}
                  </s>
                </Badge>{' '}
                <small className='font-italic text-muted'>
                  t/m {dayjs(currentDiscountToInc).format('D MMMM')}!
                </small>
              </h5>
              <h3 className='m-0'>
                <Badge color='success'>
                  {currency(cost - currentDiscount)}
                </Badge>
              </h3>
            </>
          ) : (
            <h4 className='m-0'>
              <Badge color='success'>{currency(cost)}</Badge>
            </h4>
          )}
          {!!deposit && (
            <small>
              <i>voorschot: {currency(deposit)}</i>
            </small>
          )}
        </Col>
        <Col sm='2' className='mt-2 mt-sm-0'>
          {view === 'canceled' && (
            <Button color='warning' block outline size='sm' disabled>
              <FontAwesomeIcon icon={faBan} /> Geannuleerd
            </Button>
          )}

          {view === 'disabled' && (
            <Button color='info' block outline size='sm' disabled>
              <FontAwesomeIcon icon={faPencilAlt} /> Inschrijven
            </Button>
          )}

          {view === 'registrations' && (
            <UncontrolledDropdown>
              <DropdownToggle caret color='info' block outline size='sm'>
                <FontAwesomeIcon icon={faPencilAlt} /> Inschrijven
              </DropdownToggle>
              <DropdownMenu right>
                {availableStudents.map((student, i) => (
                  <DropdownItem key={i} {...student}>
                    <CartActions student={student} camp={camp} />
                  </DropdownItem>
                ))}

                {registeredStudents.map((student, i) => (
                  <DropdownItem key={i} {...student}>
                    <Button outline color='success' disabled>
                      <FontAwesomeIcon icon={faCheck} /> Ingeschreven
                    </Button>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </Col>
      </Row>
    </ListGroupItem>
  )
}

export default CampCard
