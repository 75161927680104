import React from 'react'

import Table from 'reactstrap/lib/Table'
import Alert from 'reactstrap/lib/Alert'
import Button from 'reactstrap/lib/Button'
import { Link } from 'react-router-dom'
import orderBy from 'lodash/orderBy'
import { useAlert } from 'react-alert'

import StatusLegend from '../partials/StatusLegend'
import DateRange from '../partials/DateRange'
import { statusMap, periodMap, currency } from '../../helpers'
import { useMutation } from '@apollo/client'
import { CANCEL_REGISTRATION, registrationParts } from '../../gql/registrations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import { updateUserCache } from '../../gql/users'
import { FETCH_ORDERS } from '../../gql/orders'

const RegistrationList = ({ registrations, orderFormat = false, ...props }) => {
  const alert = useAlert()
  const [cancelRegistration] = useMutation(CANCEL_REGISTRATION, {
    update (
      cache,
      {
        data: {
          cancelRegistration: { credit, registration }
        }
      }
    ) {
      updateUserCache(cache, { credit })
      cache.writeFragment({
        id: cache.identify(registration),
        fragment: registrationParts,
        fragmentName: 'RegistrationParts',
        data: registration
      })
    },
    refetchQueries: [{ query: FETCH_ORDERS }],
    onCompleted: ({ cancelRegistration: { credit } }) => {
      alert.success(
        'Registratie geannuleerd. Het eventueel betaalde bedrag werd toegevoegd aan uw beschikbaar saldo.'
      )
    }
  })

  const onCancel = id => {
    if (
      window.confirm(
        'Weet u zeker dat u deze registratie wilt annuleren? Het reeds betaalde bedrag zal worden toegevoegd aan uw account. Hiermee kunnen toekomstige inschrijvingen worden betaald.'
      )
    ) {
      cancelRegistration({ variables: { id } })
    }
  }
  return registrations.length > 0 ? (
    <div className='table-responsive'>
      <Table {...props}>
        <thead>
          <tr>
            <th>Naam kind</th>
            <th>Locatie</th>
            <th>Data</th>
            {orderFormat && (
              <>
                <th>Prijs</th>
                <th>Administratiekost</th>
                <th>Betaald</th>
              </>
            )}
            <th>Status</th>
            {!orderFormat && (
              <>
                <th>Bestelnr</th>
                <th />
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {orderBy(registrations, ({ camp: { from } }) => from).map(
            (
              {
                id,
                student,
                location,
                camp,
                status,
                orderId,
                calculatedCost: { discount, cost, subTotal },
                paid,
                canCancel
              },
              i
            ) => {
              const { icon } = statusMap[status]
              return (
                <tr key={`registration-tr-${i}`}>
                  <td>{student && student.name}</td>
                  <td>{location && location.name}</td>
                  <td>
                    {camp.id && (
                      <>
                        {periodMap[camp.period]} <DateRange {...camp} />
                      </>
                    )}
                  </td>
                  {orderFormat && (
                    <>
                      <td>
                        {discount && <s>{currency(cost)}</s>}
                        {currency(subTotal)}
                      </td>
                      <td>{currency(paid)}</td>
                    </>
                  )}
                  <td>{icon}</td>
                  {!orderFormat && (
                    <>
                      <td>
                        <Link to={`/bestellingen/${orderId}`}>{orderId}</Link>
                      </td>
                      <td>
                        {canCancel && (
                          <Button
                            title='Inschrijving annuleren'
                            size='sm'
                            color='danger'
                            onClick={() => onCancel(id)}
                          >
                            <FontAwesomeIcon icon={faBan} />
                          </Button>
                        )}
                      </td>
                    </>
                  )}
                </tr>
              )
            }
          )}
        </tbody>
      </Table>
      <StatusLegend />
    </div>
  ) : (
    <Alert color='secondary'>Geen inschrijvingen gevonden.</Alert>
  )
}

export default RegistrationList
