import { useQuery } from '@apollo/client'
import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import Loader from './Loader'
import { FETCH_CURRENT_USER } from '../../gql/users'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { data, loading, error } = useQuery(FETCH_CURRENT_USER)
  if (error) console.error(error)
  return (
    <Route
      {...rest}
      render={props =>
        loading ? (
          <Loader />
        ) : !data.currentUser ? (
          <Redirect to='/login' />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}

export default PrivateRoute
