import Col from 'reactstrap/lib/Col'
import CustomFormField from '../partials/CustomFormField'
import MultipleSelectFormField from '../partials/MultipleSelectFormField'
import { Field } from 'formik'

import { campOptions } from 'enums'

const { GADGETS, TSHIRTSIZE, SHORTSIZE, SHOESIZE } = campOptions

const sizeOptions = [
  '116 (XS kids)',
  '128 (S kids)',
  '140 (M kids)',
  '152 (L kids)',
  '164 (XL kids)',
  'XS (adults)',
  'S (adults)',
  'M (adults)',
  'L (adults)'
]

const Gadgets = ({ formik }) => (
  <Col xs='12' sm='12' className='mt-2 mt-sm-0'>
    <label htmlFor='gadgets' className='mr-2'>
      Kies twee gadgets:
    </label>
    <br />
    <Field
      name='gadgets'
      type='text'
      component={MultipleSelectFormField}
      options={[
        'zonnebril',
        'turnzak',
        'drinkenbus',
        'brooddoos',
        'pet',
        'pennenzak',
        'beachball',
        'frisbee'
      ]}
      max={2}
    />
    <br />
    <small className='text-danger'>{formik.errors['gadgets']}</small>
  </Col>
)

const TshirtSize = () => (
  <Col xs='12' sm='2'>
    <label htmlFor='tshirtSize' className='mr-2'>
      T-shirt maat:
    </label>
    <Field
      id='tshirtSize'
      name='tshirtSize'
      type='select'
      bsSize='sm'
      options={sizeOptions}
      component={CustomFormField}
    />
  </Col>
)

const ShortSize = () => (
  <Col xs='12' sm='2'>
    <label htmlFor='shortSize' className='mr-2'>
      Short maat:
    </label>
    <Field
      id='shortSize'
      name='shortSize'
      type='select'
      bsSize='sm'
      options={sizeOptions}
      component={CustomFormField}
    />
  </Col>
)

const ShoeSize = () => (
  <Col xs='12' sm='2'>
    <label htmlFor='shoeSize' className='mr-2'>
      Schoenmaat:
    </label>
    <Field
      id='shoeSize'
      name='shoeSize'
      type='select'
      bsSize='sm'
      options={Array.from({ length: 18 }, (_, i) => i + 28)}
      component={CustomFormField}
    />
  </Col>
)

const CartItemOptions = {
  [GADGETS]: Gadgets,
  [TSHIRTSIZE]: TshirtSize,
  [SHORTSIZE]: ShortSize,
  [SHOESIZE]: ShoeSize
}

export default CartItemOptions
