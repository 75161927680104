import React, { useState } from 'react'
import Collapse from 'reactstrap/lib/Collapse'
import Navbar from 'reactstrap/lib/Navbar'
import NavbarToggler from 'reactstrap/lib/NavbarToggler'
import Nav from 'reactstrap/lib/Nav'
import NavItem from 'reactstrap/lib/NavItem'
import UncontrolledDropdown from 'reactstrap/lib/UncontrolledDropdown'
import DropdownToggle from 'reactstrap/lib/DropdownToggle'
import DropdownMenu from 'reactstrap/lib/DropdownMenu'
import DropdownItem from 'reactstrap/lib/DropdownItem'
import Badge from 'reactstrap/lib/Badge'
import { NavLink, Link } from 'react-router-dom'
import {
  faUser,
  faSignOutAlt,
  faUserEdit
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useApolloClient, useQuery } from '@apollo/client'
import { FETCH_CURRENT_USER, logout } from '../gql/users'
import { currency } from '../helpers'

const Header = () => {
  const apolloClient = useApolloClient()
  const { data, loading } = useQuery(FETCH_CURRENT_USER)

  const navItems = [
    {
      route: '/',
      title: 'Voetbalstages'
    },
    {
      route: '/inschrijvingen',
      title: 'Inschrijvingen'
    },
    {
      route: '/bestellingen',
      title: 'Bestellingen'
    }
  ]

  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <div>
      <Navbar color='light' light expand='md' className='row'>
        <NavbarToggler onClick={toggle} />
        <div className='flex-column mx-auto'>
          <div className='mx-auto header-img'>
            <Link to='/'>
              <img
                className='img-fluid'
                src='/voetbalstages-logo.png'
                alt='Logo voetbalstages.be'
              />
            </Link>
          </div>
          <Collapse isOpen={isOpen} navbar>
            <Nav className='mx-auto' navbar>
              {navItems.map(({ route, title }) => (
                <NavItem key={`nav-link-${route}`}>
                  <NavLink to={route} className='nav-link'>
                    {title}
                  </NavLink>
                </NavItem>
              ))}
              {data?.currentUser?.isAdmin ||
                (data?.currentUser?.isManager && (
                  <NavItem>
                    <a href='/admin' className='nav-link'>
                      ADMIN
                    </a>
                  </NavItem>
                ))}
              {!loading && data.currentUser && (
                <>
                  {!!data.currentUser.credit && (
                    <NavItem>
                      {/* eslint-disable-next-line */}
                      <a className='nav-link' href='#' aria-disabled='true'>
                        <Badge color='warning'>
                          Beschikbaar saldo: {currency(data.currentUser.credit)}
                        </Badge>
                      </a>
                    </NavItem>
                  )}
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      <FontAwesomeIcon icon={faUser} />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem disabled>
                        Hallo {data.currentUser.fullName}!
                      </DropdownItem>
                      <DropdownItem divider />
                      <Link to='/profiel' className='dropdown-item'>
                        <FontAwesomeIcon icon={faUserEdit} /> Profiel aanpassen
                      </Link>
                      <DropdownItem onClick={() => logout(apolloClient)}>
                        <FontAwesomeIcon icon={faSignOutAlt} /> Afmelden
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </>
              )}
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    </div>
  )
}

export default Header
