import React from 'react'
import Button from 'reactstrap/lib/Button'

const MultipleSelectFormField = ({
  field: { name, value, onChange, onBlur },
  form: { touched, errors, setFieldValue },
  options,
  max
}) => {
  const toggleItem = option => {
    let newValue = [...value]
    if (value.includes(option)) {
      const i = value.indexOf(option)
      newValue.splice(i, 1)
    } else {
      newValue.push(option)
    }

    setFieldValue(name, newValue)
  }

  return (
    <>
      {options.map((option, i) => (
        <Button
          key={i}
          onClick={() => toggleItem(option)}
          disabled={!value.includes(option) && value.length === max}
          color={value.includes(option) ? 'success' : 'secondary'}
          className='mr-2 mb-2 py-0 px-2'
        >
          {option} {value.includes(option) && <span aria-hidden='true'>×</span>}
        </Button>
      ))}
    </>
  )
}

export default MultipleSelectFormField
