import React, { useEffect, useState } from 'react'
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import Card from 'reactstrap/lib/Card'
import CardBody from 'reactstrap/lib/CardBody'
import CardTitle from 'reactstrap/lib/CardTitle'
import Button from 'reactstrap/lib/Button'
import Alert from 'reactstrap/lib/Alert'
import {
  faExclamationTriangle,
  faUserEdit,
  faUserPlus
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLazyQuery, useQuery } from '@apollo/client'

import Loader from '../partials/Loader'
import Student from './Student'
import { FETCH_STUDENTS } from '../../gql/students'
import { FETCH_CURRENT_USER } from '../../gql/users'

const Students = ({ setCompleted }) => {
  const [loadStudents, { called, data, loading, error }] = useLazyQuery(
    FETCH_STUDENTS
  )

  const { data: userData } = useQuery(FETCH_CURRENT_USER)

  useEffect(() => {
    setCompleted(data && data.allStudents && !!data.allStudents[0])
    if (
      userData &&
      userData.currentUser &&
      !userData.currentUser.isAdmin &&
      !userData.currentUser.isManager
    ) {
      loadStudents()
    }
  }, [setCompleted, loadStudents, userData, data])

  const [editing, setEditing] = useState(null)

  if (!userData || !userData.currentUser)
    return (
      <Alert color='warning'>
        <FontAwesomeIcon icon={faExclamationTriangle} /> Log in om verder te
        gaan.
      </Alert>
    )

  if (userData.currentUser.isAdmin || userData.currentUser.isManager)
    return (
      <Alert color='danger'>
        Ingelogd als admin of manager, kinderen worden niet geladen.
      </Alert>
    )

  if (loading || !called) return <Loader />
  if (error) console.error(error)

  const { allStudents } = data

  if (editing) return <Student student={editing} setEditing={setEditing} />

  return (
    <Row>
      {allStudents.map(student => (
        <Col md={4} key={`student-${student.id}`}>
          <Card className='mb-3'>
            <CardBody className='py-3'>
              <div className='d-flex justify-content-between align-items-center'>
                <CardTitle className='m-0'>{student.name}</CardTitle>
                <Button
                  outline
                  color='secondary'
                  size='sm'
                  onClick={() => setEditing(student)}
                >
                  <FontAwesomeIcon icon={faUserEdit} />
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
      <Col md={3}>
        <Button
          outline
          size='lg'
          color='success'
          onClick={() => setEditing({})}
          block
        >
          <FontAwesomeIcon icon={faUserPlus} /> Kind toevoegen
        </Button>
      </Col>
    </Row>
  )
}

export default Students
