module.exports = {
  roles: {
    ADMIN: 'ADMIN',
    MANAGER: 'MANAGER'
  },
  status: {
    OPEN: 'OPEN',
    CANCELED: 'CANCELED',
    HIDDEN: 'HIDDEN'
  },
  topics: {
    REGISTRATION: 'REGISTRATION',
    ORDER: 'ORDER',
    PAYMENT: 'PAYMENT',
    STUDENT: 'STUDENT',
    USER: 'USER',
    SETTINGS: 'SETTINGS',
    CAMP: 'CAMP',
    ORGANIZATION: 'ORGANIZATION',
    LOCATION: 'LOCATION'
  },
  campOptions: {
    GADGETS: 'GADGETS',
    TSHIRTSIZE: 'TSHIRTSIZE',
    SHORTSIZE: 'SHORTSIZE',
    SHOESIZE: 'SHOESIZE'
  }
}
