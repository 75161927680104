import React from 'react'
import Form from 'reactstrap/lib/Form'
import Button from 'reactstrap/lib/Button'
import Badge from 'reactstrap/lib/Badge'
import Row from 'reactstrap/lib/Row'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons'
import { Formik } from 'formik'

import { currency, periodMap } from '../../helpers'

import DateRange from '../partials/DateRange'

import cart, { cartItemSchema, GET_CART } from '../../gql/cart'
import { useQuery } from '@apollo/client'
import CartItemOptions from './CartItemOptions'

const CartItem = ({ student, camp, tshirtSize = '', gadgets = [] }) => {
  const {
    location,
    costDetail: { cost, currentDiscount, deposit },
    options
  } = camp

  const {
    data: { oneOrgValid }
  } = useQuery(GET_CART)

  return (
    <>
      <hr />
      <div className='d-flex justify-content-between align-items-start mb-2'>
        <span>
          <h4>{student.name}</h4>
          {!oneOrgValid && (
            <h5>
              <Badge color='danger'>
                <FontAwesomeIcon icon={faExclamationTriangle} /> Organisatie:{' '}
                {location.organization.name}
              </Badge>
            </h5>
          )}
          <h5 style={{ display: 'inline' }}>
            <Badge color='primary'>{location.name}</Badge>
          </h5>{' '}
          <b>{camp.name && camp.name}</b> {periodMap[camp.period]}{' '}
          <DateRange {...camp} />
          <br />
          <p className='my-2'>
            <b>Prijs: </b>
            {currentDiscount ? (
              <>
                <s>{currency(cost)}</s> {currency(cost - currentDiscount)}
              </>
            ) : (
              currency(cost)
            )}{' '}
            {deposit ? (
              <>
                <b>Voorschot:</b> {currency(deposit)}
              </>
            ) : null}{' '}
          </p>
        </span>
        <Button
          outline
          color='secondary'
          size='sm'
          onClick={() => cart.removeItem({ student, camp })}
          className='mt-3'
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </div>
      <div>
        <Formik
          initialValues={{ tshirtSize, gadgets }}
          validationSchema={cartItemSchema(options)}
          validate={doc => {
            cart.editItem(student.id, camp.id, doc)
          }}
        >
          {formik => (
            <Form onSubmit={formik.handleSubmit} className='mb-2'>
              <Row>
                {options.map((option, i) => {
                  const OptionInput = CartItemOptions[option]
                  return <OptionInput formik={formik} key={i} />
                })}
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default CartItem
