import React, { useEffect, useState } from 'react'
import Alert from 'reactstrap/lib/Alert'
import { useQuery } from '@apollo/client'

import { FETCH_CURRENT_USER } from '../../gql/users'
import Loader from '../partials/Loader'
import Login from './Login'
import Register from './Register'

const AccountWizard = ({ setCompleted }) => {
  const { data, loading, error } = useQuery(FETCH_CURRENT_USER)

  useEffect(() => setCompleted(data && data.currentUser))

  const [registering, setRegistering] = useState(false)
  const toggleRegistering = () => setRegistering(!registering)

  if (loading) return <Loader />
  if (error) console.error(error)

  const { currentUser } = data

  return currentUser ? (
    <Alert
      color={
        currentUser.isAdmin || currentUser.isManager ? 'danger' : 'success'
      }
      className='mb-0'
    >
      {currentUser.isAdmin || currentUser.isManager
        ? 'Ingelogd als admin of manager!'
        : 'Ingelogd!'}
    </Alert>
  ) : registering ? (
    <Register setRegistering={toggleRegistering} />
  ) : (
    <Login setRegistering={toggleRegistering} />
  )
}

export default AccountWizard
