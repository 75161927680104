import { useQuery } from '@apollo/client'
import React from 'react'
import { Card } from 'reactstrap'
import { CardTitle } from 'reactstrap/lib'
import { FETCH_ORDERS } from '../../gql/orders'
import Loader from '../partials/Loader'
import OrderList from '../partials/OrderList'

const OutstandingOrders = () => {
  const { data, error, loading } = useQuery(FETCH_ORDERS, {
    variables: { filter: { isOutstanding: true } }
  })

  if (loading) return <Loader />
  if (error) console.error(error)

  const { allOrders } = data || {}

  if (loading || !allOrders?.length) return null

  return (
    <Card body color='warning' className='py-1 px-3 mt-3'>
      <CardTitle>
        <h3 className='text-white mb-0 mt-1'>Te betalen inschrijvingen</h3>
      </CardTitle>
      <OrderList orders={allOrders} filter={{ isOutstanding: true }} />
    </Card>
  )
}

export default OutstandingOrders
