import { gql } from '@apollo/client'
import { registrationParts } from './registrations'

const orderParts = gql`
  fragment OrderParts on Order {
    id
    registrations {
      ...RegistrationParts
    }
    registrationCount
    status
    calculatedCost {
      total
      paid
      open
      credit
    }
    createdAt
  }
  ${registrationParts}
`

const FETCH_ORDERS = gql`
  query Orders($filter: OrderFilter) {
    allOrders(filter: $filter) {
      ...OrderParts
    }
  }
  ${orderParts}
`

const PAY_ORDER = gql`
  mutation PayOrder($id: ID!) {
    payOrder(id: $id) {
      order {
        ...OrderParts
      }
      payUrl
      credit
    }
  }
  ${orderParts}
`

export { FETCH_ORDERS, PAY_ORDER, orderParts }
