import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className='my-3'>
      <h1>Privacy Policy</h1>
      <p>
        Privacy policy voor Sport Concepts VZW, eigenaar van voetbalstages.be
      </p>
      <h5>1) Waarborgen Privacy</h5>
      <p>
        Het waarborgen van de privacy van bezoekers van voetbalstages.be is een
        belangrijke taak voor ons. Daarom beschrijven we in onze privacy policy
        welke informatie we verzamelen en hoe we deze informatie gebruiken.
      </p>
      <h5>2) Toestemming</h5>
      <p>
        Door de informatie en de diensten op voetbalstages.be te gebruiken, gaat
        u akkoord met onze privacy policy en de voorwaarden die wij hierin
        hebben opgenomen.
      </p>
      <h5>3) Vragen</h5>
      <p>
        Als u meer informatie wilt ontvangen, of vragen hebt over de privacy
        policy van Sport Concepts VZW en specifiek voetbalstages.be, kun u ons
        benaderen via e-mail. Ons e-mailadres is info@voetbalstages.be.
      </p>
      <h5>4) Monitoren gedrag bezoeker</h5>
      <p>
        voetbalstages.be maakt gebruik van verschillende technieken om bij te
        houden wie de website bezoekt, hoe deze bezoeker zich op de website
        gedraagt en welke pagina’s worden bezocht. Dat is een gebruikelijke
        manier van werken voor websites omdat het informatie oplevert op die
        bijdraagt aan de kwaliteit van de gebruikerservaring. De informatie die
        we, via cookies, registreren, bestaat uit onder meer IP-adressen, het
        type browser en de bezochte pagina’s.
      </p>
      <p>
        Tevens monitoren we waar bezoekers de website voor het eerst bezoeken en
        vanaf welke pagina ze vertrekken. Deze informatie houden we anoniem bij
        en is niet gekoppeld aan andere persoonlijke informatie, hier.
      </p>
      <h5>5) Gebruik van cookies</h5>
      <p>
        voetbalstages.be plaatst cookies bij bezoekers. Dat doen we om
        informatie te verzamelen over de pagina’s die gebruikers op onze website
        bezoeken, om bij te houden hoe vaak bezoekers terug komen en om te zien
        welke pagina’s het goed doen op de website. Ook houden we bij welke
        informatie de browser deelt.
      </p>
      <h5>6) Cookies uitschakelen</h5>
      <p>
        U kunt er voor kiezen om cookies uit te schakelen. Dat doet u door
        gebruik te maken de mogelijkheden van uw browser. U vindt meer
        informatie over deze mogelijkheden op de website van de aanbieder van uw
        browser.
      </p>
    </div>
  )
}

export default PrivacyPolicy
