import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faCheckDouble,
  faClock,
  faBan,
  faRedoAlt,
  faClipboardCheck
} from '@fortawesome/free-solid-svg-icons'
import * as Yup from 'yup'

const userSchema = Yup.object({
  firstName: Yup.string().required('verplicht veld'),
  lastName: Yup.string().required('verplicht veld'),
  email: Yup.string()
    .email('geen geldig emailadres')
    .required('verplicht veld'),
  telephone: Yup.array()
    .of(
      Yup.string()
        .matches(
          /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{8,9}$)/,
          'dit is geen geldig telefoonnummer'
        )
        .required('verplicht veld')
    )
    .strict()
    .required('verplicht veld'),
  address: Yup.object({
    postcode: Yup.string()
      .required('verplicht veld')
      .matches(
        /^([1-9][0-9]{3})([a-rA-RT-Z][a-zA-Z]|[S][BCE-RT-Z])?$/,
        'dit is geen geldige postcode'
      )
  })
})

const currency = n =>
  new Intl.NumberFormat('nl-BE', {
    style: 'currency',
    currency: 'EUR'
  }).format(n)

const statusMap = {
  open: {
    text: 'Bevestigd - nog te betalen',
    icon: (
      <span className='text-info'>
        <FontAwesomeIcon icon={faCheck} />
      </span>
    )
  },
  payment_pending: {
    text: 'Bevestigd - betaling in behandeling',
    icon: (
      <span className='text-warning'>
        <FontAwesomeIcon icon={faClock} />
      </span>
    )
  },
  confirmed: {
    text: 'Bevestigd - betaald',
    icon: (
      <span className='text-success'>
        <FontAwesomeIcon icon={faCheckDouble} />
      </span>
    )
  },
  deposited: {
    text: 'Bevestigd - voorschot betaald',
    icon: (
      <span className='text-success'>
        <FontAwesomeIcon icon={faClipboardCheck} />
      </span>
    )
  },
  canceled: {
    text: 'Geannuleerd',
    icon: (
      <span className='text-danger'>
        <FontAwesomeIcon icon={faBan} />
      </span>
    )
  },
  refunded: {
    text: 'Geannuleerd - terugbetaald',
    icon: (
      <>
        <span className='text-danger'>
          <FontAwesomeIcon icon={faBan} />
        </span>{' '}
        <span className='text-info'>
          <FontAwesomeIcon icon={faRedoAlt} />
        </span>
      </>
    )
  }
}

const periodMap = {
  krokus: 'Krokus',
  pasen_1: 'Pasen 1',
  pasen_2: 'Pasen 2',
  zomer_1: 'Zomer 1',
  zomer_2: 'Zomer 2',
  zomer_3: 'Zomer 3',
  zomer_4: 'Zomer 4',
  zomer_5: 'Zomer 5',
  zomer_6: 'Zomer 6',
  zomer_7: 'Zomer 7',
  zomer_8: 'Zomer 8',
  zomer_9: 'Zomer 9',
  herfst: 'Herfst'
}

export { currency, statusMap, userSchema, periodMap }
