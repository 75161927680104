import React, { useEffect, useState } from 'react'
import Button from 'reactstrap/lib/Button'
import Alert from 'reactstrap/lib/Alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCashRegister,
  faCheck,
  faClipboardCheck,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client'
import { useAlert } from 'react-alert'
import { Link } from 'react-router-dom'

import { currency, userSchema } from '../../helpers'
import Loader from '../partials/Loader'

import cart, { GET_CART } from '../../gql/cart'
import { FETCH_CURRENT_USER } from '../../gql/users'
import { FETCH_ORGANIZATION } from '../../gql/organizations'

import CartItem from './CartItem'

const Cart = () => {
  const {
    data: {
      cartItems,
      cartDepositTotal,
      cartTotal,
      cartDetailsValid,
      oneOrgValid,
      forcePay
    }
  } = useQuery(GET_CART)

  const { data: userData, loading } = useQuery(FETCH_CURRENT_USER)

  const [isUserValid, setIsUserValid] = useState(false)

  useEffect(() => {
    setIsUserValid(
      userSchema.isValidSync((userData && userData.currentUser) || {})
    )
  }, [userData])

  const apolloClient = useApolloClient()
  const alert = useAlert()

  const checkoutCart = async params => {
    const { result, message } = await cart.checkout(apolloClient)(params)
    alert[result](message)
  }

  const [loadOrg, { data: orgData, loading: orgLoading }] = useLazyQuery(
    FETCH_ORGANIZATION
  )

  useEffect(() => {
    if (cartItems[0]) {
      loadOrg({
        variables: { id: cartItems[0].camp.location.organization.id }
      })
    }
  }, [cartItems, loadOrg])

  const [termsAccepted, setTermsAccepted] = useState(false)

  const cartIsValid =
    cartDetailsValid && isUserValid && termsAccepted && oneOrgValid

  if (loading || orgLoading) return <Loader />

  if (!userData.currentUser)
    return (
      <Alert color='warning'>
        <FontAwesomeIcon icon={faExclamationTriangle} /> Login in om verder te
        gaan.
      </Alert>
    )

  const {
    currentUser: { credit }
  } = userData

  const { Organization: organization } = orgData || {}

  return (
    <div>
      {cartItems.length > 0 && organization ? (
        <>
          {cartItems.map((item, i) => (
            <CartItem key={i} {...item} />
          ))}
          {/* <hr /> */}
          {!!credit && (
            <>
              <hr className='m-0' />
              <div className='d-flex justify-content-between py-2 px-1'>
                <h5 className='my-0'>Subtotaal</h5>
                <h5 className='my-0'>{currency(cartTotal)}</h5>
              </div>
              {!!cartDepositTotal && (
                <>
                  <hr className='m-0' />
                  <div className='d-flex justify-content-between py-2 px-1'>
                    <h5 className='my-0'>Voorschot subtotaal</h5>
                    <h5 className='my-0'>{currency(cartDepositTotal)}</h5>
                  </div>
                </>
              )}
              <hr className='m-0' />
              <div className='d-flex justify-content-between py-2 px-1'>
                <h5 className='my-0'>Beschikbaar saldo*</h5>
                <h5 className='my-0'>{currency(credit)}</h5>
              </div>
            </>
          )}
          <hr className='m-0' />
          <div className='d-flex justify-content-between py-2 px-1'>
            <h5 className='my-0'>Totaal</h5>
            <h5 className='my-0'>
              {currency(Math.max(0, cartTotal - credit))}
            </h5>
          </div>
          {!!cartDepositTotal && (
            <>
              <hr className='m-0' />
              <div className='d-flex justify-content-between py-2 px-1'>
                <h5 className='my-0'>Voorschot totaal</h5>
                <h5 className='my-0'>
                  {currency(Math.max(0, cartDepositTotal - credit))}
                </h5>
              </div>
            </>
          )}
          {oneOrgValid && (
            <>
              <hr className='mt-0 mb-3' />
              <div className='form-group form-check'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  id='terms'
                  onChange={({ target: { checked } }) =>
                    setTermsAccepted(checked)
                  }
                />
                <label className='form-check-label' htmlFor='terms'>
                  Ik accepteer{' '}
                  <Link
                    to={`/algemene_voorwaarden_${organization.path}`}
                    target='_blank'
                  >
                    de algemene voorwaarden
                  </Link>{' '}
                  van {organization.name}
                </label>
              </div>
            </>
          )}
          <hr className='mt-0 mb-3' />
          {cartIsValid && (
            <div className='d-flex flex-column align-items-end mb-4'>
              <Button
                color='success'
                onClick={() => checkoutCart()}
                className='mb-2'
              >
                <FontAwesomeIcon icon={faCashRegister} /> Inschrijving
                bevestigen en totaal afrekenen
              </Button>
              {!!cartDepositTotal && (
                <Button
                  color='info'
                  onClick={() => checkoutCart({ deposit: true })}
                  className='mb-2'
                >
                  <FontAwesomeIcon icon={faClipboardCheck} /> Inschrijving
                  bevestigen en voorschot afrekenen
                </Button>
              )}
              {!forcePay && (
                <Button
                  outline
                  color='secondary'
                  onClick={() => checkoutCart({ payNow: false })}
                >
                  <FontAwesomeIcon icon={faCheck} /> Inschrijving bevestigen en
                  op een later tijdstip afrekenen
                </Button>
              )}
            </div>
          )}
          {!!credit && (
            <small className='text-muted'>
              * Indien u over beschikbaar saldo beschikt zal dit automatisch
              gebruikt worden om nieuwe registraties af te rekenen.
            </small>
          )}
          {!cartIsValid && (
            <Alert color='warning'>
              {!oneOrgValid && (
                <div className='mb-2'>
                  <FontAwesomeIcon icon={faExclamationTriangle} /> Gelieve
                  inschrijvingen bij verschillende organisaties apart te boeken.
                </div>
              )}
              {!isUserValid && (
                <div className='mb-2'>
                  <FontAwesomeIcon icon={faExclamationTriangle} /> Gelieve uw
                  contactgegevens te vervolledigen om de inschrijvingen te
                  kunnen bevestigen.
                </div>
              )}
              {!cartDetailsValid && (
                <div className='mb-2'>
                  <FontAwesomeIcon icon={faExclamationTriangle} /> Gelieve voor
                  iedere inschrijving de opties te kiezen.
                </div>
              )}
              {!termsAccepted && oneOrgValid && (
                <div className='mb-2'>
                  <FontAwesomeIcon icon={faExclamationTriangle} /> Gelieve de
                  algemene voorwaarden te accepteren.
                </div>
              )}
            </Alert>
          )}
        </>
      ) : (
        <Alert color='warning'>
          <FontAwesomeIcon icon={faExclamationTriangle} /> Nog geen te
          bevestigen inschrijvingen.
        </Alert>
      )}
    </div>
  )
}

export default Cart
