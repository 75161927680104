import React, { useState } from 'react'
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import Form from 'reactstrap/lib/Form'
import FormGroup from 'reactstrap/lib/FormGroup'
import Label from 'reactstrap/lib/Label'
import Button from 'reactstrap/lib/Button'
import Alert from 'reactstrap/lib/Alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { Link, useHistory } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'

import { login } from '../../gql/users'
import { Formik, Field } from 'formik'
import CustomFormField from '../partials/CustomFormField'

const Login = ({ setRegistering }) => {
  const [loginFailed, setLoginFailed] = useState(false)
  const history = useHistory()
  const apolloClient = useApolloClient()

  const onSubmit = async doc => {
    const success = await login(apolloClient)(doc)
    setLoginFailed(!success)
    if (success) {
      history.push('/')
    }
  }

  return (
    <Row>
      <Col sm={{ size: 4, offset: 4 }}>
        <a className='btn btn-outline-primary btn-block' href='/auth/google'>
          <FontAwesomeIcon icon={faGoogle} /> Login met Google
        </a>
        <a className='btn btn-outline-primary btn-block' href='/auth/facebook'>
          <FontAwesomeIcon icon={faFacebookF} /> Login met Facebook
        </a>
        {loginFailed && (
          <Alert color='danger' className='mt-3'>
            Logingegevens zijn niet correct.
          </Alert>
        )}
        <Formik
          initialValues={{ email: '', password: '' }}
          validate={values => {
            const errors = {}
            if (!values.email) {
              errors.email = 'Verplicht veld'
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Geen geldig emailadres'
            }
            return errors
          }}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit} className='my-3'>
              <FormGroup>
                <Label for='email'>Email</Label>
                <Field type='email' name='email' component={CustomFormField} />
              </FormGroup>
              <FormGroup>
                <Label for='password'>Wachtwoord</Label>
                <Field
                  type='password'
                  name='password'
                  component={CustomFormField}
                />
              </FormGroup>
              <Button
                type='submit'
                block
                color='primary'
                disabled={isSubmitting}
              >
                Login
              </Button>
            </Form>
          )}
        </Formik>
        <p>
          Geen account?{' '}
          {setRegistering ? (
            // eslint-disable-next-line
            <a href='#' onClick={setRegistering}>
              Klik hier
            </a>
          ) : (
            <Link to='/register'>Klik hier</Link>
          )}{' '}
          om u aan te melden. Wachtwoord vergeten?{' '}
          <Link to='/wachtwoord-vergeten'>Klik hier.</Link>
        </p>
      </Col>
    </Row>
  )
}

export default Login
