import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Provider as AlertProvider, positions } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

import PrivateRoute from './partials/PrivateRoute'

import Dashboard from './Dashboard'
import Header from './Header'
import Login from './user/Login'
import Register from './user/Register'
import Profile from './user/Profile'
import Registrations from './registrations/Registrations'
import Orders from './registrations/Orders'
import ResetPassword from './user/ResetPassword'
import SetPassword from './user/SetPassword'
import PrivacyPolicy from './user/PrivacyPolicy'
import CookieConsent from './partials/CookieConsent'
import Terms from './Terms'
// import GlobalMessage from './partials/GlobalMessage'

const alertOptions = {
  position: positions.TOP_CENTER,
  timeout: 5000
}

const App = () => (
  <>
    {/* <GlobalMessage /> */}
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <BrowserRouter>
        <div className='container-lg mb-5'>
          <Header />
          <Switch>
            <Route path='/login' component={Login} />
            <Route path='/register' component={Register} />
            <Route path='/wachtwoord-vergeten/:token' component={SetPassword} />
            <Route path='/wachtwoord-vergeten' component={ResetPassword} />
            <Route path='/privacy' component={PrivacyPolicy} />
            <Route path='/algemene_voorwaarden*' component={Terms} />
            <PrivateRoute path='/inschrijvingen' component={Registrations} />
            <PrivateRoute path='/profiel' component={Profile} />
            <PrivateRoute path='/bestellingen/:orderId' component={Orders} />
            <PrivateRoute path='/bestellingen' component={Orders} />
            <Route path={['/:locationPath', '/']} component={Dashboard} />
            <Route path='*'>
              <Redirect to='/' />
            </Route>
          </Switch>
        </div>
      </BrowserRouter>
    </AlertProvider>
    <CookieConsent />
  </>
)

export default App
