import React from 'react'
import {
  faAddressCard,
  faShoppingCart,
  faChild,
  faGraduationCap,
  faUser
} from '@fortawesome/free-solid-svg-icons'

import Students from './students/Students'
import Cart from './registrations/Cart'
import AccountWizard from './user/AccountWizard'
import Profile from './user/Profile'
import DashboardElement from './partials/DashboardElement'
import Locations from './camps/Locations'
import OutstandingOrders from './registrations/OutstandingOrders'

const Dashboard = () => (
  <>
    <OutstandingOrders />
    <h1 className='mt-3'>Inschrijven voor voetbalstages.be</h1>
    <DashboardElement icon={faUser} title='1. Log in of creëer een account'>
      <AccountWizard />
    </DashboardElement>
    <DashboardElement
      icon={faChild}
      title='2. Voeg kinderen toe aan uw account'
    >
      <Students />
    </DashboardElement>
    <DashboardElement
      // completed={registrations && (!!registrations[0] || !!cartItems[0])}
      icon={faGraduationCap}
      title='3. Kies de stages'
    >
      <Locations />
    </DashboardElement>
    <DashboardElement
      icon={faAddressCard}
      title='4. Vul uw contactgegevens aan'
    >
      <Profile />
    </DashboardElement>
    <DashboardElement
      icon={faShoppingCart}
      title='5. Bevestig de inschrijvingen'
    >
      <Cart />
    </DashboardElement>
  </>
)

export default Dashboard
