import React from 'react'
import { useQuery } from '@apollo/client'

import { FETCH_REGISTRATIONS } from '../../gql/registrations'
import Loader from '../partials/Loader'
import RegistrationList from '../partials/RegistrationList'

const Registrations = () => {
  const { data, loading, error } = useQuery(FETCH_REGISTRATIONS)

  if (error) console.error(error)

  return (
    <div className='my-3'>
      <h1>Inschrijvingen</h1>
      <p>
        Hieronder vindt u een overzicht van uw inschrijvingen. Indien de
        inschrijving nog betaald dient te worden kan u klikken op het
        bestelnummer.
      </p>
      {loading ? (
        <Loader />
      ) : (
        <RegistrationList registrations={data.allRegistrations} />
      )}
    </div>
  )
}

export default Registrations
