import { gql } from '@apollo/client'

const registrationParts = gql`
  fragment RegistrationParts on Registration {
    id
    student {
      id
      name
    }
    location {
      id
      name
    }
    camp {
      id
      from
      to
      period
    }
    orderId
    status
    calculatedCost {
      cost
      currentDiscount
      subTotal
      total
    }
    paid
    canCancel
  }
`

const FETCH_REGISTRATIONS = gql`
  query Registrations {
    allRegistrations {
      ...RegistrationParts
    }
  }
  ${registrationParts}
`

const CANCEL_REGISTRATION = gql`
  mutation CancelRegistration($id: ID!) {
    cancelRegistration(id: $id) {
      registration {
        id
        status
        paid
        canCancel
      }
      credit
    }
  }
`

export { FETCH_REGISTRATIONS, CANCEL_REGISTRATION, registrationParts }
