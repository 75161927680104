import { bugsnagApiKey } from 'config'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import './style/main.scss'
import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/client'

import App from './components/App'
import Error from './components/Error'

import { polyfillLoader } from 'polyfill-io-feature-detection'
import apolloClient from './apolloClient'

import dayjs from 'dayjs'
import 'dayjs/locale/nl'

Bugsnag.start({
  apiKey: bugsnagApiKey || process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

dayjs.locale('nl')

polyfillLoader({
  features: 'Promise,fetch',
  onCompleted: main
})

function main () {
  ReactDOM.render(
    <ErrorBoundary FallbackComponent={Error}>
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    </ErrorBoundary>,
    document.getElementById('root')
  )
}
