import React from 'react'
import get from 'lodash/get'
import Input from 'reactstrap/lib/Input'
import FormFeedback from 'reactstrap/lib/FormFeedback'

const CustomFormField = ({
  field,
  form: { touched, errors },
  options,
  ...props
}) => {
  return (
    <div>
      <Input
        invalid={!!(get(touched, field.name) && get(errors, field.name))}
        {...field}
        {...props}
      >
        {options && (
          <>
            <option>-</option>
            {options.map(option => (
              <option key={`${field.name}-option-${option}`}>{option}</option>
            ))}
          </>
        )}
      </Input>
      {get(touched, field.name) && get(errors, field.name) && (
        <FormFeedback>{get(errors, field.name)}</FormFeedback>
      )}
    </div>
  )
}

export default CustomFormField
