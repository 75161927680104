import React from 'react'
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import Form from 'reactstrap/lib/Form'
import FormGroup from 'reactstrap/lib/FormGroup'
import Label from 'reactstrap/lib/Label'
import Input from 'reactstrap/lib/Input'
import Button from 'reactstrap/lib/Button'
import Alert from 'reactstrap/lib/Alert'
import { Link } from 'react-router-dom'

const ResetPassword = ({
  history: {
    location: { search }
  }
}) => {
  const params = new URLSearchParams(search)
  const failed = params.get('failed')
  const success = params.get('success')
  return (
    <Row className='my-3'>
      <Col sm={{ size: 4, offset: 4 }}>
        {failed && <Alert color='warning'>Gebruiker niet gevonden</Alert>}
        {success && (
          <Alert color='success'>
            Er werd een email met reset link gestuurd.
          </Alert>
        )}
        <Form action='/auth/reset_password' method='post' className='my-3'>
          <FormGroup>
            <Label for='email'>Email</Label>
            <Input type='email' name='email' />
          </FormGroup>
          <Button type='submit' block color='primary'>
            Reset wachtwoord
          </Button>
        </Form>
        <p>
          <Link to='/login'>Keer terug</Link> om in te loggen.
        </p>
      </Col>
    </Row>
  )
}

export default ResetPassword
