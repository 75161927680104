import { gql, useQuery } from '@apollo/client'
import Loader from './partials/Loader'

const GET_TERMS = gql`
  query Terms($path: String) {
    terms: getTerms(path: $path)
  }
`

const Terms = ({ location: { pathname } }) => {
  const path = pathname.split('_')[2]

  const { data, loading } = useQuery(GET_TERMS, { variables: { path } })

  if (loading) return <Loader />

  return <div dangerouslySetInnerHTML={{ __html: data.terms }} />
}

export default Terms
