import React, { useEffect, useState } from 'react'
import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoBox
} from '@react-google-maps/api'
import { googleMapsApiKey } from 'config'

import Loader from '../partials/Loader'

const Map = ({ allLocations, onSelect, selectedLocation }) => {
  const [mapRef, setMapRef] = useState(null)
  const [infoOpen, setInfoOpen] = useState(false)

  useEffect(() => {
    const fitBounds = map => {
      const bounds = new window.google.maps.LatLngBounds()
      allLocations.map(({ id, address }) => {
        bounds.extend(address)
        return id
      })
      map.fitBounds(bounds)
    }
    if (mapRef) {
      if (selectedLocation) {
        mapRef.panTo(selectedLocation.address)
        mapRef.setZoom(12)
        setInfoOpen(true)
      } else {
        fitBounds(mapRef)
      }
    }
  }, [allLocations, selectedLocation, mapRef])

  const { isLoaded } = useLoadScript({
    googleMapsApiKey:
      googleMapsApiKey || process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    // preventGoogleFontsLoading: true
    mapIds: ['ee8570c6c9404dfd']
  })

  if (!isLoaded) return <Loader />

  const markerClickHandler = (event, location) => {
    onSelect(location.path)

    if (infoOpen) {
      setInfoOpen(false)
    }

    setInfoOpen(true)
  }

  return (
    <GoogleMap
      onLoad={setMapRef}
      mapContainerStyle={{
        height: '24rem',
        width: '100%'
      }}
      clickableIcons={false}
      options={{
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        mapId: 'ee8570c6c9404dfd'
      }}
    >
      {allLocations.map(location => (
        <Marker
          key={location.id}
          position={location.address}
          onClick={event => markerClickHandler(event, location)}
          icon={{
            url: location.logo?.uri
              ? location.logo.uri
              : '/voetbalstages-marker.png',
            scaledSize: { width: 72, height: 72 }
          }}
        />
      ))}

      {infoOpen && selectedLocation && (
        <InfoBox
          position={selectedLocation.address}
          // options={{ disableAutoPan: true }}
          onCloseClick={() => setInfoOpen(false)}
        >
          <div className='card bg-info text-light'>
            <div className='card-header p-2'>
              <h4 className='mb-0'>{selectedLocation.name}</h4>
            </div>
            <div className='card-body p-2'>
              <p className='card-text mb-1'>{selectedLocation.addressString}</p>
              <p className='card-text'>
                <b>{selectedLocation.camps.length}</b> stages beschikbaar
              </p>
            </div>
          </div>
        </InfoBox>
      )}
    </GoogleMap>
  )
}

export default Map
