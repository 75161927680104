import { gql } from '@apollo/client'

const campParts = gql`
  fragment CampParts on Camp {
    id
    name
    period
    duration
    costDetail {
      cost
      currentDiscount
      currentDiscountToInc
      deposit
      depositTotal
      total
      forcePay
    }
    from
    to
    status
    options
    location {
      id
      name
      organization {
        id
        name
      }
    }
    registeredStudents {
      id
      name
    }
    availableStudents {
      id
      name
    }
  }
`

const FETCH_CAMP = gql`
  query Camp($id: ID!) {
    Camp(id: $id) {
      ...CampParts
    }
  }
  ${campParts}
`

export { FETCH_CAMP, campParts }
