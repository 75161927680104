import { gql } from '@apollo/client'

const FETCH_ORGANIZATION = gql`
  query Organization($id: ID, $path: String) {
    Organization(id: $id, path: $path) {
      id
      name
      path
    }
  }
`

export { FETCH_ORGANIZATION }
