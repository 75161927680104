import { gql } from '@apollo/client'
import axios from 'axios'
import cart from './cart'

const userParts = gql`
  fragment UserParts on User {
    id
    firstName
    lastName
    fullName
    email
    secondEmail
    telephone
    address {
      postcode
    }
    credit
    isAdmin
    isManager
  }
`

const FETCH_CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      ...UserParts
    }
  }
  ${userParts}
`

const UPDATE_CURRENT_USER = gql`
  mutation UpdateCurrentUser(
    $firstName: String
    $lastName: String
    $email: String
    $secondEmail: String
    $telephone: [String]
    $address: AddressInput
  ) {
    updateCurrentUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      secondEmail: $secondEmail
      telephone: $telephone
      address: $address
    ) {
      ...UserParts
    }
  }
  ${userParts}
`

const updateUserCache = (cache, data) => {
  const { currentUser } = cache.readQuery({ query: FETCH_CURRENT_USER })
  const id = cache.identify(currentUser)
  cache.writeFragment({
    id,
    fragment: userParts,
    data
  })
}

const login = apolloClient => async doc => {
  let result
  try {
    result = await axios.post('/auth/login', doc)
  } catch (error) {
    result = false
  }
  apolloClient.resetStore()
  return !!result
}

const logout = async apolloClient => {
  cart.reset()
  await axios.get('/api/logout')
  apolloClient.resetStore()
}

export {
  FETCH_CURRENT_USER,
  UPDATE_CURRENT_USER,
  userParts,
  updateUserCache,
  logout,
  login
}
