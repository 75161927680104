import React from 'react'
import Input from 'reactstrap/lib/Input'
import FormGroup from 'reactstrap/lib/FormGroup'
import Label from 'reactstrap/lib/Label'

const Checkbox = ({
  field: { name, value: formikValue },
  form: { errors, setFieldValue },
  label,
  hideErrors = false,
  ...rest
}) => {
  const handleChange = () => {
    const values = formikValue || []
    const index = values.indexOf(rest.value)
    if (index === -1) {
      values.push(rest.value)
    } else {
      values.splice(index, 1)
    }
    setFieldValue(name, values)
  }
  return (
    <>
      <FormGroup check>
        <Label check>
          <Input
            type='checkbox'
            onChange={handleChange}
            checked={formikValue.indexOf(rest.value) !== -1}
            {...rest}
          />{' '}
          {label}
        </Label>
      </FormGroup>
      {!hideErrors && errors[name] && (
        <small className='text-danger'>{errors[name]}</small>
      )}
    </>
  )
}

export default Checkbox
