import React from 'react'
import orderBy from 'lodash/orderBy'
import Card from 'reactstrap/lib/Card'
import CardBody from 'reactstrap/lib/CardBody'
import Table from 'reactstrap/lib/Table'
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import UncontrolledCollapse from 'reactstrap/lib/UncontrolledCollapse'
import Button from 'reactstrap/lib/Button'
import Badge from 'reactstrap/lib/Badge'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCashRegister, faInfo } from '@fortawesome/free-solid-svg-icons'
import { useAlert } from 'react-alert'
import { gql, useMutation } from '@apollo/client'
import { updateUserCache } from '../../gql/users'

import { currency, statusMap } from '../../helpers'
import { FETCH_ORDERS, PAY_ORDER } from '../../gql/orders'

import RegistrationList from '../partials/RegistrationList'

const OrderList = ({ orders, filter }) => {
  const alert = useAlert()

  const [payOrder] = useMutation(PAY_ORDER, {
    update (
      cache,
      {
        data: {
          payOrder: { credit }
        }
      }
    ) {
      updateUserCache(cache, { credit })
      const { allOrders } = cache.readQuery({
        query: FETCH_ORDERS,
        variables: filter ? { filter } : {}
      })
      allOrders.forEach(o =>
        cache.writeFragment({
          id: cache.identify(o),
          fragment: gql`
            fragment NewCalculatedCost on Order {
              calculatedCost
            }
          `,
          fragmentName: 'NewCalculatedCost',
          data: {
            calculatedCost: {
              ...o.calculatedCost,
              credit,
              total: Math.max(
                0,
                o.calculatedCost.open - o.calculatedCost.paid - credit
              )
            }
          }
        })
      )
    },
    onCompleted: ({ payOrder: { payUrl } }) => {
      !!payUrl && window.location.replace(payUrl)
      alert.success('Bestelling succesvol voldaan.')
    }
  })

  return orderBy(orders, 'createdAt', 'desc').map(
    ({
      id,
      createdAt,
      calculatedCost: { open, paid, credit, total },
      status,
      registrations,
      registrationCount
    }) => {
      const { icon, text } = statusMap[status]
      let payButton
      switch (status) {
        case 'open':
          payButton = 'Betaling uitvoeren'
          break
        case 'payment_pending':
          payButton = 'Betaalscherm opnieuw openen'
          break
        case 'deposited':
          payButton = 'Totaal afrekenen'
          break
        default:
          payButton = null
      }
      if (!!payButton && !!credit) {
        if (!open) {
          payButton = 'Beschikbaar saldo gebruiken om af te rekenen'
        } else {
          payButton =
            'Beschikbaar saldo gebruiken en te betalen bedrag afrekenen'
        }
      }
      return (
        <Card key={`order-row-${id}`} className='mb-3'>
          <CardBody>
            <Row>
              <Col xs='12' sm='4' className='mb-3'>
                <Row>
                  <Col xs='6' sm='12'>
                    <h5>Bestelnummer</h5>
                    <h5>
                      <Badge color='info'>{id}</Badge>
                    </h5>
                  </Col>
                  <Col xs='6' sm='12'>
                    <h5>Besteld op</h5>
                    <h5>
                      <Badge color='info'>
                        {dayjs(createdAt).format('DD/MM/YYYY HH:mm')}
                      </Badge>
                    </h5>
                  </Col>
                </Row>
              </Col>

              <Col xs='6' sm='3'>
                {(payButton || status === 'confirmed') && (
                  <Table size='sm' borderless>
                    <tbody>
                      <tr>
                        <th>Totaal</th>
                        <td></td>
                        <th className='text-right'>{currency(total)}</th>
                      </tr>
                      {!!paid && (
                        <tr>
                          <th>Betaald</th>
                          <td>-</td>
                          <td className='text-right'>{currency(paid)}</td>
                        </tr>
                      )}
                      {!!credit && (
                        <tr>
                          <th>Beschikbaar saldo</th>
                          <td>-</td>
                          <td className='text-right'>{currency(credit)}</td>
                        </tr>
                      )}
                      <tr className='border-top'>
                        <th>Te betalen</th>
                        <td>=</td>
                        <th className='text-right'>{currency(open)}</th>
                      </tr>
                    </tbody>
                  </Table>
                )}
              </Col>
              <Col xs='6' sm={{ size: 4, offset: 1 }}>
                <h5>Status</h5>
                <div>
                  {icon} {text}
                </div>
                {payButton && (
                  <Button
                    color='success'
                    outline
                    className='mt-2'
                    onClick={() => payOrder({ variables: { id } })}
                  >
                    <FontAwesomeIcon icon={faCashRegister} /> {payButton}
                  </Button>
                )}
              </Col>
            </Row>

            <Button
              color='primary'
              outline
              id={`registrations-for-${id}`}
              className='mt-3'
            >
              <FontAwesomeIcon icon={faInfo} /> {registrationCount}{' '}
              {registrationCount === 1 ? 'inschrijving' : 'inschrijvingen'}
            </Button>
            <UncontrolledCollapse toggler={`#registrations-for-${id}`}>
              <RegistrationList
                registrations={registrations}
                orderFormat
                className='mt-3'
              />
            </UncontrolledCollapse>
          </CardBody>
        </Card>
      )
    }
  )
}

export default OrderList
