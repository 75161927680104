import React, { useEffect } from 'react'
import { Field, FieldArray, useFormik, FormikProvider } from 'formik'
import Form from 'reactstrap/lib/Form'
import FormGroup from 'reactstrap/lib/FormGroup'
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import Alert from 'reactstrap/lib/Alert'
import { useMutation, useQuery } from '@apollo/client'
import * as Yup from 'yup'

import CustomFormField from '../partials/CustomFormField'
import ArrayFormField from '../partials/ArrayFormField'
import Loader from '../partials/Loader'
import { userSchema } from '../../helpers'
import { FETCH_CURRENT_USER, UPDATE_CURRENT_USER } from '../../gql/users'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

const ProfileForm = ({ initialValues }) => {
  const [updateCurrentUser] = useMutation(UPDATE_CURRENT_USER)

  const initialTouched = {
    firstName: true,
    lastName: true,
    email: true,
    'address.postcode': true,
    'telephone.0': true
  }

  if (initialValues.telephone) {
    let i
    for (i = 1; i < initialValues.telephone.length; i++) {
      initialTouched[`telephone.${i}`] = true
    }
  }

  let timer

  const formik = useFormik({
    initialValues,
    validationSchema: userSchema,
    validate: doc => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        if (
          Yup.string()
            .required()
            .email()
            .isValidSync(doc.email)
        ) {
          updateCurrentUser({ variables: doc })
        }
      }, 4000)
    },
    initialTouched
  })

  useEffect(() => formik.validateForm(), [initialValues])

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Row form>
          <Col xs='6' md='5'>
            <FormGroup>
              <label htmlFor='firstName'>Voornaam*</label>
              <Field
                id='firstName'
                name='firstName'
                type='text'
                component={CustomFormField}
              />
            </FormGroup>
          </Col>
          <Col xs='6' md='5'>
            <FormGroup>
              <label htmlFor='lastName'>Achternaam*</label>
              <Field
                id='lastName'
                name='lastName'
                type='text'
                component={CustomFormField}
              />
            </FormGroup>
          </Col>
          <Col xs='6' md='2'>
            <FormGroup>
              <label htmlFor='postcode'>Postcode*</label>
              <Field
                id='address.postcode'
                name='address.postcode'
                type='text'
                component={CustomFormField}
              />
            </FormGroup>
          </Col>
          <Col xs='6' md='4'>
            <FormGroup>
              <label htmlFor='friends'>Telefoonnummers*</label>
              <FieldArray
                name='telephone'
                type='text'
                component={ArrayFormField}
              />
              <small className='text-secondary'>
                Belangrijk: Via deze nummers zijn de ouders tijdens de stage te
                bereiken!
              </small>
            </FormGroup>
          </Col>
          <Col xs='12' md='4'>
            <FormGroup>
              <label htmlFor='email'>Emailadres*</label>
              <Field
                id='email'
                name='email'
                type='email'
                component={CustomFormField}
              />
            </FormGroup>
          </Col>
          <Col xs='12' md='4'>
            <FormGroup>
              <label htmlFor='secondEmail'>Emailadres andere ouder</label>
              <Field
                id='secondEmail'
                name='secondEmail'
                type='email'
                component={CustomFormField}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </FormikProvider>
  )
}

const Profile = ({ setCompleted }) => {
  const { data, loading } = useQuery(FETCH_CURRENT_USER)

  useEffect(() => {
    if (setCompleted) {
      setCompleted(userSchema.isValidSync((data && data.currentUser) || {}))
    }
  })

  const { currentUser = {} } = data || {}

  const initialValues = {
    firstName: currentUser?.firstName || '',
    lastName: currentUser?.lastName || '',
    email: currentUser?.email || '',
    telephone:
      currentUser?.telephone && currentUser?.telephone[0]
        ? currentUser.telephone
        : [''],
    secondEmail: currentUser?.secondEmail || '',
    address: currentUser?.address ? currentUser.address : { postcode: '' }
  }

  if (loading) return <Loader />
  if (!currentUser)
    return (
      <Alert color='warning'>
        <FontAwesomeIcon icon={faExclamationTriangle} /> Log in om verder te
        gaan.
      </Alert>
    )
  return <ProfileForm {...{ initialValues }} />
}

export default Profile
