import { makeVar, gql } from '@apollo/client'
import sumBy from 'lodash/sumBy'
import { campParts } from './camps'
import { studentParts } from './students'
import { FETCH_REGISTRATIONS } from './registrations'
import * as Yup from 'yup'
import { FETCH_ORDERS, orderParts } from './orders'
import { updateUserCache } from './users'
import uniqBy from 'lodash/uniqBy'
import some from 'lodash/some'

import { campOptions } from 'enums'

const { GADGETS, TSHIRTSIZE, SHORTSIZE, SHOESIZE } = campOptions

const GET_CART = gql`
  query GetCart {
    cartItems @client
    cartDepositTotal @client
    cartTotal @client
    cartDetailsValid @client
    oneOrgValid @client
    forcePay @client
  }
`

const CART_INCLUDES = gql`
  query CartIncludes($student: Student, $camp: Camp) {
    cartIncludes(student: $student, camp: $camp) @client
  }
`

const CHECKOUT_CART = gql`
  mutation CreateOrder(
    $registrations: [RegistrationInput]
    $payNow: Boolean
    $deposit: Boolean
  ) {
    createOrder(
      registrations: $registrations
      payNow: $payNow
      deposit: $deposit
    ) {
      order {
        ...OrderParts
      }
      payUrl
      credit
    }
  }
  ${orderParts}
`

const campOptionsEnumTranslations = {
  [GADGETS]: 'gadgets',
  [TSHIRTSIZE]: 'tshirtSize',
  [SHORTSIZE]: 'shortSize',
  [SHOESIZE]: 'shoeSize'
}

const cartItemSchemaItems = {
  [GADGETS]: Yup.array()
    .min(2, 'Kies twee gadgets')
    .max(2, 'Kies twee gadgets'),
  [TSHIRTSIZE]: Yup.string().min(2, 'Geef de juiste maat aan'),
  [SHORTSIZE]: Yup.string().min(2, 'Geef de juiste maat aan'),
  [SHOESIZE]: Yup.number().typeError('Geef de juiste maat aan')
}

const cartItemSchema = options => {
  const schema = {}
  options.forEach(
    option =>
      (schema[campOptionsEnumTranslations[option]] =
        cartItemSchemaItems[option])
  )
  return Yup.object(schema)
}

const items = makeVar([])

const cartDetailsValid = makeVar(false)

const checkCartDetailsValid = () => {
  let result = true
  items().forEach(({ camp, ...item }) => {
    if (!cartItemSchema(camp.options).isValidSync(item)) {
      result = false
    }
  })
  cartDetailsValid(result)
}

const oneOrgValid = makeVar(false)

const checkDifferentOrgs = () => {
  const result = uniqBy(items(), 'camp.location.organization.id').length === 1
  oneOrgValid(result)
}

const forcePay = makeVar(false)

const checkForcePay = () => {
  const result = some(items(), 'camp.costDetail.forcePay')
  forcePay(result)
}

const setCartItems = (newItems = []) => {
  items(newItems)
  window.localStorage.setItem(
    'svs__cart__items',
    JSON.stringify(
      newItems.map(({ camp, student, ...rest }) => ({
        campId: camp.id,
        studentId: student.id,
        ...rest
      }))
    )
  )
  checkCartDetailsValid()
  checkDifferentOrgs()
  checkForcePay()
}

const updateLocalstorage = apolloClient => {
  const CART_ITEMS = gql`
    query CartItems($campIds: [ID]) {
      availableCamps(ids: $campIds) {
        ...CampParts
      }
      allStudents {
        ...StudentParts
      }
    }
    ${campParts}
    ${studentParts}
  `

  const currentStorage =
    JSON.parse(window.localStorage.getItem('svs__cart__items')) || []

  const initialOrg = window.localStorage.getItem('svs__organization__path')

  if (currentStorage.length > 0) {
    const campIds = currentStorage.map(({ campId }) => campId)

    apolloClient
      .query({
        query: CART_ITEMS,
        variables: { campIds }
      })
      .then(({ data: { allStudents, availableCamps } }) => {
        if (
          initialOrg === window.localStorage.getItem('svs__organization__path')
        ) {
          const updatedStorage = currentStorage.filter(
            ({ campId, studentId }) =>
              !!availableCamps.find(({ id }) => id === campId) &&
              !!allStudents.find(({ id }) => id === studentId)
          )
          setCartItems(
            updatedStorage.map(({ campId, studentId, ...rest }) => {
              return {
                camp: availableCamps.find(({ id }) => id === campId),
                student: allStudents.find(({ id }) => id === studentId),
                ...rest
              }
            })
          )
        } else {
          setCartItems()
        }
      })
  }
}

const cart = {
  addItem: item => {
    setCartItems([...items(), item])
  },
  removeItem: ({ student, camp }) => {
    setCartItems(
      items().filter(
        item => item.student.id !== student.id || item.camp.id !== camp.id
      )
    )
  },
  reset: () => setCartItems([]),
  editItem: (studentId, campId, doc) => {
    const i = items().findIndex(
      ({ student, camp }) => studentId === student.id && campId === camp.id
    )
    const newItems = items()
    items()[i] = { ...items()[i], ...doc }
    setCartItems(newItems)
  },
  includes: ({ student, camp }) =>
    items().find(
      item => item.student.id === student.id && item.camp.id === camp.id
    ),
  checkout: apolloClient => async ({ payNow = true, deposit = false } = {}) => {
    try {
      const {
        data: {
          createOrder: { payUrl }
        }
      } = await apolloClient.mutate({
        mutation: CHECKOUT_CART,
        variables: {
          registrations: items().map(({ camp, student, ...rest }) => ({
            studentId: student.id,
            campId: camp.id,
            ...rest
          })),
          payNow,
          deposit
        },
        update (
          cache,
          {
            data: {
              createOrder: { credit }
            }
          }
        ) {
          updateUserCache(cache, { credit })
        },
        refetchQueries: [
          { query: FETCH_ORDERS },
          { query: FETCH_REGISTRATIONS }
        ]
      })
      setCartItems()
      if (payUrl) window.location.replace(payUrl)
      return {
        result: 'success',
        message: 'Inschrijving(en) succesvol geregistreerd.'
      }
    } catch (error) {
      console.error(error)
      return { result: 'error', message: 'Er ging iets mis' }
    }
  }
}

const cartFields = {
  cartItems: {
    read: () => items()
  },
  cartIncludes: {
    read: (_, { args: { student, camp } }) =>
      !!items().find(
        item => item.student.id === student.id && item.camp.id === camp.id
      )
  },
  cartDepositTotal: {
    read: () => sumBy(items(), 'camp.costDetail.depositTotal')
  },
  cartTotal: {
    read: () => sumBy(items(), 'camp.costDetail.total')
  },
  cartDetailsValid: {
    read: () => cartDetailsValid()
  },
  oneOrgValid: {
    read: () => oneOrgValid()
  },
  forcePay: {
    read: () => forcePay()
  }
}

export default cart
export {
  GET_CART,
  CART_INCLUDES,
  CHECKOUT_CART,
  cartFields,
  updateLocalstorage,
  cartItemSchema,
  setCartItems
}
