import React from 'react'
import { statusMap } from '../../helpers'
import Badge from 'reactstrap/lib/Badge'

const StatusLegend = () =>
  Object.keys(statusMap).map((k, i) => (
    <Badge color='light' key={`legend-${i}`} className='mr-2'>
      {statusMap[k].icon} {statusMap[k].text}
    </Badge>
  ))

export default StatusLegend
