import React from 'react'
import Alert from 'reactstrap/lib/Alert'
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import Form from 'reactstrap/lib/Form'
import FormGroup from 'reactstrap/lib/FormGroup'
import Button from 'reactstrap/lib/Button'
import { withRouter, Link } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as yup from 'yup'

import CustomFormField from '../partials/CustomFormField'
import Checkbox from '../partials/CheckboxFormField'

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Geen geldig emailadres')
    .required('verplicht veld'),
  password: yup
    .string()
    .required('verplicht veld')
    .min(6, 'Wachtwoord moet uit minstens 6 tekens bestaan'),
  repeatPassword: yup
    .string()
    .required('verplicht veld')
    .when('password', {
      is: val => !!(val && val.length > 0),
      then: yup
        .string()
        .oneOf([yup.ref('password')], 'Wachtwoord komt niet overeen')
    }),
  agreeToTerms: yup
    .array()
    .required(
      'Om inscrijvingen te kunnen doen moet u akkoord gaan met de algemene voorwaarden.'
    )
})

const Register = ({
  history: {
    location: { search }
  },
  setRegistering
}) => {
  const params = new URLSearchParams(search)
  const failed = params.get('failed')
  return (
    <Row className='my-3'>
      <Col sm={{ size: 4, offset: 4 }}>
        {failed && (
          <Alert color='warning'>
            Er bestaat al een gebruiker met dit emailadres.
          </Alert>
        )}
        <Formik
          initialValues={{
            email: '',
            password: '',
            repeatPassword: '',
            agreeToTerms: []
          }}
          validationSchema={validationSchema}
          onSubmit={async doc => {
            try {
              const res = await fetch('/auth/register', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                redirect: 'follow',
                mode: 'same-origin',
                body: JSON.stringify(doc)
              })
              if (res.redirected) {
                window.location.href = res.url
              }
            } catch (err) {
              console.error(err)
            }
          }}
        >
          {formik => (
            <Form onSubmit={formik.handleSubmit} className='my-3'>
              <FormGroup>
                <label htmlFor='email'>Emailadres</label>
                <Field
                  id='email'
                  name='email'
                  type='email'
                  component={CustomFormField}
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor='password'>Wachtwoord</label>
                <Field
                  id='password'
                  name='password'
                  type='password'
                  component={CustomFormField}
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor='repeatPassword'>Herhaal wachtwoord</label>
                <Field
                  id='repeatPassword'
                  name='repeatPassword'
                  type='password'
                  component={CustomFormField}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  id='agreeToTerms'
                  name='agreeToTerms'
                  value='agrees'
                  label={
                    <>
                      Ik ga akkoord met de{' '}
                      <Link to='/algemene_voorwaarden'>
                        algemene voorwaarden
                      </Link>
                    </>
                  }
                  component={Checkbox}
                />
              </FormGroup>
              <Button type='submit' block color='primary'>
                Account aanmaken
              </Button>
            </Form>
          )}
        </Formik>
        <p>
          Al een account?{' '}
          {setRegistering ? (
            // eslint-disable-next-line
            <a href='#' onClick={setRegistering}>
              Klik hier
            </a>
          ) : (
            <Link to='/login'>Klik hier</Link>
          )}{' '}
          om in te loggen.
        </p>
      </Col>
    </Row>
  )
}

export default withRouter(Register)
